function calculateViewportScrollProgress (viewport) {
  const scrollableDistance = viewport.scrollHeight - viewport.clientHeight;

  if (scrollableDistance <= 0) return 0;

  return viewport.scrollTop / scrollableDistance;
}

function calculateTargetScrollProgress (viewport, target) {
  const targetBCR = target.getBoundingClientRect(),
        targetCenter = targetBCR.top + (targetBCR.height / 2),
        viewportCenter = viewport.clientHeight / 2;

  return (targetCenter - viewportCenter) / viewportCenter;
}

function trackVisibleTargets (targets) {
  const visibleTargets = new Set();

  const updateVisibleTargets = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) visibleTargets.add(entry.target);
      else visibleTargets.delete(entry.target);
    });
  };

  const observer = new IntersectionObserver(updateVisibleTargets, { root: null });

  targets.forEach(target => observer.observe(target));

  return visibleTargets;
}

export default function (
  viewportSelector = "body",
  targetsSelector,
  options = {
    viewportScrollProgressPropName: "--viewport-scroll-progress",
    targetScrollProgressPropName: "--target-scroll-progress",
}) {

  const viewport = document.querySelector(viewportSelector);
  const targets = viewport.querySelectorAll(targetsSelector);
  const visibleTargets = trackVisibleTargets(targets);

  const updateScrollProgress = () => {
    window.requestAnimationFrame(() => {
      viewport.style.setProperty(
        options.viewportScrollProgressPropName,
        calculateViewportScrollProgress(viewport).toFixed(2)
      );

      visibleTargets.forEach(target => {
        target.style.setProperty(
          options.targetScrollProgressPropName,
          calculateTargetScrollProgress(viewport, target).toFixed(2)
        );
      });
    });
  };

  viewport.addEventListener("scroll", updateScrollProgress);

  updateScrollProgress();
}
