import getRandomInt from "./get-random-int";

export default function (el, options = {
  duration: 1000,
  frequency: 10000,
  activeClassName: "is-shooting",
}) {
  if (el === null) return el;

  let timeoutID;
  setInterval(() => {
    el.style.top = getRandomInt(0, 25) + "%";
    el.style.left = getRandomInt(0, 75) + "%";
    el.classList.add(options.activeClassName);

    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      el.style.top = null;
      el.style.left = null;
      el.classList.remove(options.activeClassName);
    }, options.duration);
  }, options.frequency);
}
