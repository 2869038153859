import throttle from "./throttle";

export default function (el = document) {
  const scrollUp = new CustomEvent("scrollup");
  const scrollDown = new CustomEvent("scrolldown");

  let touchstartY = 0;

  const dispatchWheelScrollDirection = throttle(e => {
    if (e.deltaY > 0) el.dispatchEvent(scrollUp);
    else if (e.deltaY < 0) el.dispatchEvent(scrollDown);
  });

  const dispatchKeyboardScrollDirection = e => {
    if (e.key === "ArrowUp") el.dispatchEvent(scrollDown);
    else if (e.key === "ArrowDown") el.dispatchEvent(scrollUp);
  };

  const trackTouchInitialPosition = e => {
    touchstartY = e.changedTouches[0].screenY;
  };

  const dispatchTouchScrollDirection = e => {
    const touchendY = e.changedTouches[0].screenY;

    if (touchendY < touchstartY) el.dispatchEvent(scrollUp);
    else if (touchendY > touchstartY) el.dispatchEvent(scrollDown);
  };

  const start = () => {
    if (!el.hasAttribute("tabindex")) el.setAttribute("tabindex", "-1");

    el.focus();

    el.addEventListener("wheel", dispatchWheelScrollDirection, { passive: true });
    el.addEventListener("keyup", dispatchKeyboardScrollDirection);
    el.addEventListener("touchstart", trackTouchInitialPosition, { passive: true });
    el.addEventListener("touchend", dispatchTouchScrollDirection, { passive: true });
  };

  const stop = () => {
    if (el.getAttribute("tabindex") === "-1") el.removeAttribute("tabindex");

    el.removeEventListener("wheel", dispatchWheelScrollDirection);
    el.removeEventListener("keyup", dispatchKeyboardScrollDirection);
    el.removeEventListener("touchstart", trackTouchInitialPosition);
    el.removeEventListener("touchend", dispatchTouchScrollDirection);
  };

  return { start, stop };
}
