import { inject } from "@vercel/analytics";
import registerHotkeys from "./modules/register-hotkeys";
import Slideshow from "./modules/slideshow";
import trackScrollProgress from "./modules/track-scroll-progress";
import meteor from "./modules/meteor";

window.onload = () => document.querySelector(".page").classList.remove("loading");
registerHotkeys();
Slideshow(document.querySelector(".slideshow"));
trackScrollProgress(".page", ".slideshow .slide");
meteor(document.querySelector(".meteor"));

inject();
