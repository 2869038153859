export const smallScreen = window.matchMedia("screen and (max-width: 48em), screen and (max-height: 48em)");

export function atMedia(mediaQueryList, matchesFn, doesntMatchFn) {
  if (!mediaQueryList) return;

  const handleChange = mql => {
    if (mql.matches) matchesFn?.();
    else doesntMatchFn?.();
  };

  handleChange(mediaQueryList);
  mediaQueryList.addEventListener("change", handleChange);
}
