export default function () {
  const elements = document.querySelectorAll("[data-hotkey]");

  if (elements.length < 1) return;

  const activeClass = "active";

  elements.forEach(element => {
    const hotkey = element.dataset.hotkey;

    document.addEventListener("keydown", e => {
      if (e.key === hotkey) element.classList.add(activeClass);
    });

    document.addEventListener("keyup", e => {
      if (e.key === hotkey) {
        element.classList.remove(activeClass);
        element.click();
      }
    });
  });
}
