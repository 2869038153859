import dispatchScrollDirectionEvents from "./scroll-direction-events";
import { atMedia, smallScreen } from "./media-queries";

export default function (el, options = {
  slidesSelector: ".slide",
  navLinksSelector: "nav .link",
  slideIndexPropName: "--slide-index",
  scrollBumpFactorPropName: "--scroll-bump-factor",
  activeSlideIndexPropName: "--active-slide-index",
  activeClassName: "active",
}) {
  if (el === null) return el;

  const slides = el.querySelectorAll(options.slidesSelector);

  const navLinks = el.querySelectorAll(options.navLinksSelector);

  const getActiveSlideIndex = () => {
    const activeSlideIndex = document.body.style.getPropertyValue(options.activeSlideIndexPropName);

    if (activeSlideIndex.length !== 0) return Number(activeSlideIndex);

    return -1;
  };

  const setActiveSlideIndex = i => {
    const activeSlideIndex = getActiveSlideIndex();

    if (activeSlideIndex === i) return;

    if (activeSlideIndex !== -1) {
      slides[activeSlideIndex].classList.remove(options.activeClassName);
      navLinks[activeSlideIndex].classList.remove(options.activeClassName);
    }

    document.body.style.setProperty(options.activeSlideIndexPropName, i.toString());
    slides[i].classList.add(options.activeClassName);
    navLinks[i].classList.add(options.activeClassName);
  };

  const clampIndex = i => {
    if (i < 0) return [0, -1];
    else if (i > slides.length - 1) return [slides.length - 1, 1];
    else return [i , 0];
  };

  const moveTo = (index = 0) => {
    const [clampedIndex, clampSide] = clampIndex(index);

    setActiveSlideIndex(clampedIndex);
    document.body.style.setProperty(options.scrollBumpFactorPropName, clampSide.toString());

    if (clampSide !== 0)
      setTimeout(() => document.body.style.setProperty(options.scrollBumpFactorPropName, "0"), 150);
  };

  const moveBy = (steps = 0) => moveTo(steps + getActiveSlideIndex());

  const next = () => moveBy(1);

  const prev = () => moveBy(-1);

  for (let i = 0; i < slides.length; i++) {
    const indexStr = i.toString();

    slides[i].style.setProperty(options.slideIndexPropName, indexStr);
    navLinks[i].style.setProperty(options.slideIndexPropName, indexStr);
    navLinks[i].onclick = () => moveTo(i);
  }

  const dispatchSlideshowScrollDirectionEvents = dispatchScrollDirectionEvents(el);

  const activate = () => {
    dispatchSlideshowScrollDirectionEvents.start();

    el.addEventListener("scrollup", next);
    el.addEventListener("scrolldown", prev);

    moveTo(0);
  };

  const deactivate = () => {
    dispatchSlideshowScrollDirectionEvents.stop();

    el.removeEventListener("scrollup", next);
    el.removeEventListener("scrolldown", prev);

    document.body.style.removeProperty(options.activeSlideIndexPropName);
    document.body.style.removeProperty(options.scrollBumpFactorPropName);
  };

  atMedia(smallScreen, deactivate, activate);

  return { el, slides, nav: { moveTo, moveBy, next, prev }, activate, deactivate };
}
