export default function (fn, delay = 500) {
  let isWaiting = false;

  return (...args) => {
    if (isWaiting) return;
    fn(...args);
    setTimeout(() => { isWaiting = false }, delay);
    isWaiting = true;
  };
}
